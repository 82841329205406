export const CLEAR_ERROR = "common@CLEAR_ERROR"

// authentication
export const LOGIN_INIT = "authentication@LOGIN_INIT"
export const LOGIN_SUCCEDED =  "authentication@LOGIN_SUCCEDED"
export const LOGIN_FAILED = "authentication@LOGIN_FAILED"

export const SIGNUP_INIT = "authentication@REGISTER_INIT"
export const SIGNUP_SUCCEDED = "authentication@REGISTER_SUCCEDED"
export const SIGNUP_FAILED = "authentication@REGISTER_FAILED"

export const SIGNUP_THIRD_PARTY_INIT = "authentication@REGISTER_THIRD_PARTY_INIT"
export const SIGNUP_THIRD_PARTY_SUCCEDED = "authentication@REGISTER_THIRD_PARTY_SUCCEDED"
export const SIGNUP_THIRD_PARTY_FAILED = "authentication@REGISTER_THIRD_PARTY_FAILED"

// GoogleAuth
export const SIGNUP_GOOGLE = "authentication@SIGNUPGOOGLE_SUCCEDED"

export const LOGINGOOGLE_INIT = "authentication@LOGINGOOGLE_INIT"
export const LOGINGOOGLE_SUCCEDED =  "authentication@LOGINGOOGLE_SUCCEDED"
export const LOGINGOOGLE_FAILED = "authentication@LOGINGOOGLE_FAILED"

// FacebookAuth
export const SIGNUP_FACEBOOK = "authentication@SIGNUPFACEBOOK_SUCCEDED"

export const LOGINFACEBOOK_INIT = "authentication@LOGINFACEBOOK_INIT"
export const LOGINFACEBOOK_SUCCEDED =  "authentication@LOGINFACEBOOK_SUCCEDED"
export const LOGINFACEBOOK_FAILED = "authentication@LOGINFACEBOOK_FAILED"


export const FORGOT_PASSWORD_INIT = "authentication@FORGOT_PASSWORD_INIT"
export const FORGOT_PASSWORD_SUCCEDED = "authentication@FORGOT_PASSWORD_SUCCEDED"
export const FORGOT_PASSWORD_FAILED = "authentication@FORGOT_PASSWORD_FAILED"

export const FORGOT_PASSWORD_APPMOBIL_INIT = "authentication@FORGOT_PASSWORD_APPMOBIL_INIT"
export const FORGOT_PASSWORD_APPMOBIL_SUCCEDED = "authentication@FORGOT_PASSWORD_APPMOBIL_SUCCEDED"
export const FORGOT_PASSWORD_APPMOBIL_FAILED = "authentication@FORGOT_PASSWORD_APPMOBIL_FAILED"


//Ananlytics
export const VISITS_INIT = "analytics@VISITS_INIT"
export const VISITS_SUCCEDED = "analytics@VISITS_SUCCEDED"
export const VISITS_FAILED = "analytics@VISITS_FAILED"

export const CHECKOUTS_INIT = "analytics@CHECKOUTS_INIT"
export const CHECKOUTS_SUCCEDED = "analytics@CHECKOUTS_SUCCEDED"
export const CHECKOUTS_FAILED = "analytics@CHECKOUTS_FAILED"
// 


export const RATE_ORDER_INIT = "rate@RATE_ORDER_INIT"
export const RATE_ORDER_SUCCEDED = "rate@RATE_ORDER_SUCCEDED"
export const RATE_ORDER_FAILED = "rate@RATE_ORDER_FAILED"



export const FETCH_TOKEN_INIT = "authentication@FETCH_TOKEN_INIT"
export const FETCH_TOKEN_SUCCEDED = "authentication@FETCH_TOKEN_SUCCEDED"
export const FETCH_TOKEN_FAILED = "authentication@FETCH_TOKEN_FAILED"

export const UPDATE_PERSONAL_INFORMATION_SUCCEDED = "profile@UPDATE_PERSONAL_INFORMATION_SUCCEDED"

export const RESET_PASSWORD_INIT = "authentication@RESET_PASSWORD_INIT"
export const RESET_PASSWORD_SUCCEDED = "authentication@RESET_PASSWORD_SUCCEDED"
export const RESET_PASSWORD_FAILED = "authentication@RESET_PASSWORD_FAILED"

export const RESET_PASSWORD_MOBILE_INIT = "authentication@RESET_PASSWORD_MOBILE_INIT"
export const RESET_PASSWORD_MOBILE_SUCCEDED = "authentication@RESET_PASSWORD_MOBILE_SUCCEDED"
export const RESET_PASSWORD_MOBILE_FAILED = "authentication@RESET_PASSWORD_MOBILE_FAILED"


export const LOGOUT_INIT =  "authentication@LOGOUT_INIT"
export const LOGOUT_SUCCEDED = "authentication@LOGOUT_SUCCEDED"
export const LOGOUT_FAILED = "authentication@LOGOUT_FAILED"

// contact
export const CONTACT_US_INIT = "contactus@CONTACT_US_INIT"
export const CONTACT_US_SUCCEDED =  "contactus@CONTACT_US_SUCCEDED"
export const CONTACT_US_FAILED = "contactus@CONTACT_US_FAILED"

export const RETURNPRODUCT_INIT = "contactus@RETURNPRODUCT_INIT"
export const RETURNPRODUCT_SUCCEDED =  "contactus@CONTACT_RETURNPRODUCT"
export const RETURNPRODUCT_FAILED = "contactus@RETURNPRODUCT_FAILED"

export const FETCH_FEEDBACKS_INIT = "feedbacks@FETCH_FEEDBACKS_INIT"
export const FETCH_FEEDBACKS_SUCCEDED = "feedbacks@FETCH_FEEDBACKS_SUCCEDED"
export const FETCH_FEEDBACKS_FAILED = "feedbacks@FETCH_FEEDBACKS_FAILED"


export const FETCH_FEEDBACK_INIT = "feedbacks@FETCH_FEEDBACK_INIT"
export const FETCH_FEEDBACK_SUCCEDED = "feedbacks@FETCH_FEEDBACK_SUCCEDED"
export const FETCH_FEEDBACK_FAILED = "feedbacks@FETCH_FEEDBACK_FAILED"



export const FETCH_ORDERRETURNS_INIT = "orderreturns@FETCH_ORDERRETURNS_INIT"
export const FETCH_ORDERRETURNS_SUCCEDED = "orderreturns@FETCH_ORDERRETURNS_SUCCEDED"
export const FETCH_ORDERRETURNS_FAILED = "orderreturns@FETCH_ORDERRETURNS_FAILED"


export const FETCH_ORDERRETURN_INIT = "orderreturn@FETCH_ORDERRETURN_INIT"
export const FETCH_ORDERRETURN_SUCCEDED = "orderreturn@FETCH_ORDERRETURN_SUCCEDED"
export const FETCH_ORDERRETURN_FAILED = "orderreturn@FETCH_ORDERRETURN_FAILED"
// cart
export const ADD_TO_MY_CART = "cart@ADD_TO_CART"
export const REMOVE_FROM_CART = "cart@REMOVE_FROM_CART"
export const CLEAR_THE_CART = "cart@CLEAR_THE_CART"
export const ADD_QUANTITY = "cart@ADD_QUANTITY"
export const SUBTRACT_QUANTITY = "cart@SUBTRACT_QUANTITY"

// user account
export const FETCH_USER_INFORMATION_INIT = "user@FETCH_USER_INFORMATION_INIT"
export const FETCH_USER_INFORMATION_SUCCEDED = "user@FETCH_USER_INFORMATION_SUCCEDED"
export const FETCH_USER_INFORMATION_FAILED = "user@FETCH_USER_INFORMATION_FAILED"

export const FETCH_USER_ORDERS_INIT = "user@FETCH_USER_ORDERS_INIT"
export const FETCH_USER_ORDERS_SUCCEDED = "user@FETCH_USER_ORDERS_SUCCEDED"
export const FETCH_USER_ORDERS_FAILED = "user@FETCH_USER_ORDERS_FAILED"

export const UPDATE_USER_INFORMATION_INIT = "user@UPDATE_USER_INFORMATION_INIT"
export const UPDATE_USER_INFORMATION_SUCCEDED = "user@UPDATE_USER_INFORMATION_SUCCEDED"
export const UPDATE_USER_INFORMATION_FAILED = "user@UPDATE_USER_INFORMATION_FAILED"

// products
export const FETCH_FAMILIES_INIT = "product@FETCH_FAMILIES_INIT"
export const FETCH_FAMILIES_SUCCEDED = "products@FETCH_FETCH_FAMILIES_SUCCEDED"
export const FETCH_FAMILIES_FAILED = "products@FETCH_FAMILIES_FAILED"

export const FETCH_PRODUCTPACKTYPES_INIT = "product@FETCH_PRODUCTPACKTYPES_INIT"
export const FETCH_PRODUCTPACKTYPES_SUCCEDED = "products@FETCH_FETCH_PRODUCTPACKTYPES_SUCCEDED"
export const FETCH_PRODUCTPACKTYPES_FAILED = "products@FETCH_PRODUCTPACKTYPES_FAILED"

export const FETCH_PRODUCTSYSTEMS_INIT = "product@FETCH_PRODUCTSYSTEMS_INIT"
export const FETCH_PRODUCTSYSTEMS_SUCCEDED = "products@FETCH_FETCH_PRODUCTSYSTEMS_SUCCEDED"
export const FETCH_PRODUCTSYSTEMS_FAILED = "products@FETCH_PRODUCTSYSTEMS_FAILED"

export const FETCH_CATEGORIES_INIT = "product@FETCH_CATEGORIES_INIT"
export const FETCH_CATEGORIES_SUCCEDED = "products@FETCH_CATEGORIES_SUCCEDED"
export const FETCH_CATEGORIES_FAILED = "products@FETCH_CATEGORIES_FAILED"

export const FETCH_COLORS_INIT = "colors@FETCH_COLORS_INIT"
export const FETCH_COLORS_SUCCEDED = "colors@FETCH_COLORS_SUCCEDED"
export const FETCH_COLORS_FAILED = "colors@FETCH_COLORS_FAILED"



export const FETCH_SIZES_INIT = "product@FETCH_SIZES_INIT"
export const FETCH_SIZES_SUCCEDED = "products@FETCH_SIZES_SUCCEDED"
export const FETCH_SIZES_FAILED = "products@FETCH_SIZES_FAILED"

export const FETCH_PRODQUALITYS_INIT = "product@FETCH_PRODQUALITYS_INIT"
export const FETCH_PRODQUALITYS_SUCCEDED = "products@FETCH_PRODQUALITYS_SUCCEDED"
export const FETCH_PRODQUALITYS_FAILED = "products@FETCH_PRODQUALITYS_FAILED"

export const FETCH_PRODUCTS_INIT = "product@FETCH_PRODUCTS_INIT"
export const FETCH_PRODUCTS_SUCCEDED = "products@FETCH_PRODUCTS_SUCCEDED"
export const FETCH_PRODUCTS_FAILED = "products@FETCH_PRODUCTS_FAILED"

export const FETCH_RELATEDPRODUCT_INIT = "product@FETCH_RELATEDPRODUCT_INIT"
export const FETCH_RELATEDPRODUCT_SUCCEDED = "products@FFETCH_RELATEDPRODUCT_SUCCEDED"
export const FETCH_RELATEDPRODUCT_FAILED = "products@FETCH_RELATEDPRODUCT_FAILED"

export const FETCH_PRODUCT_INIT = "product@FETCH_PRODUCT_INIT"
export const FETCH_PRODUCT_SUCCEDED = "products@FETCH_PRODUCT_SUCCEDED"
export const FETCH_PRODUCT_FAILED = "products@FETCH_PRODUCT_FAILED"


//discount
export const FETCH_DISCOUNT_INIT = "product@FETCH_DISCOUNT_INIT"
export const FETCH_DISCOUNT_SUCCEDED = "products@FETCH_DISCOUNT_SUCCEDED"
export const FETCH_DISCOUNT_FAILED = "products@FETCH_DISCOUNT_FAILED"

export const FILTER_PRODUCTS = "products@FILTER_PRODUCTS"

export const FETCH_IN_DISCOUNT_PRODUCTS_INIT = "product@FETCH_IN_DISCOUNT_PRODUCTS_INIT"
export const FETCH_IN_DISCOUNT_PRODUCTS_SUCCEDED = "products@FETCH_IN_DISCOUNT_PRODUCTS_SUCCEDED"
export const FETCH_IN_DISCOUNT_PRODUCTS_FAILED = "products@FETCH_IN_DISCOUNT_PRODUCTS_FAILED"

export const FETCH_TAXATION_INIT = "product@FETCH_TAXATION_INIT"
export const FETCH_TAXATION_SUCCEDED = "products@FETCH_TAXATION_SUCCEDED"
export const FETCH_TAXATION_FAILED = "products@FETCH_TAXATION_FAILED"

export const FETCH_NEW_PRODUCTS_INIT = "product@FETCH_NEW_PRODUCTS_INIT"
export const FETCH_NEW_PRODUCTS_SUCCEDED = "products@FETCH_NEW_PRODUCTS_SUCCEDED"
export const FETCH_NEW_PRODUCTS_FAILED = "products@FETCH_NEW_PRODUCTS_FAILED"

export const FETCH_FAQ_INIT = "faq@FETCH_FAQ_INIT"
export const FETCH_FAQ_SUCCEDED = "faqs@FETCH_FAQ_SUCCEDED"
export const FETCH_FAQ_FAILED = "faqs@FETCH_FAQ_FAILED"

export const FETCH_NOTIFICATION_INIT = "notification@FETCH_NOTIFICATION_INIT"
export const FETCH_NOTIFICATION_SUCCEDED = "notification@FETCH_NOTIFICATION_SUCCEDED"
export const FETCH_NOTIFICATION_FAILED = "notification@FETCH_NOTIFICATION_FAILED"

export const READ_NOTIFICATION_INIT = "notification@READ_NOTIFICATION_INIT"
export const READ_NOTIFICATION_SUCCEDED = "notification@READ_NOTIFICATION_SUCCEDED"
export const READ_NOTIFICATION_FAILED = "notification@READ_NOTIFICATION_FAILED"


export const FCM_TOKEN_INIT = "user@FCM_TOKEN_INIT"
export const FCM_TOKEN_SUCCEDED = "user@FCM_TOKEN_SUCCEDED"
export const FCM_TOKEN_FAILED = "user@FCM_TOKEN_FAILED"


export const CHECKOUT_ORDER_INIT = "checkout@CHECKOUT_ORDER_INIT"
export const CHECKOUT_ORDER_SUCCEDED = "checkout@CHECKOUT_ORDER_SUCCEDED"
export const CHECKOUT_ORDER_FAILED = "checkout@CHECKOUT_ORDER_FAILED"

export const FETCH_ORDERQUOTES_INIT = "checkout@FETCH_ORDERQUOTES_INIT"
export const FETCH_ORDERQUOTES_SUCCEDED = "checkout@FETCH_ORDERQUOTES_SUCCEDED"
export const FETCH_ORDERQUOTES_FAILED = "checkout@FETCH_ORDERQUOTES_FAILED"




export const CHECKOUT_QUOTES_ORDER_INIT = "checkout@CHECKOUT__QUOTESORDER_INIT"
export const CHECKOUT_QUOTES_ORDER_SUCCEDED = "checkout@CHECKOUT_QUOTES_ORDER_SUCCEDED"
export const CHECKOUT_QUOTES_ORDER_FAILED = "checkout@CHECKOUT_QUOTES_ORDER_FAILED"

export const CHECKOUT_DEVISE_INIT = "checkout@CHECKOUT_DEVISE_INIT"
export const CHECKOUT_DEVISE_SUCCEDED = "checkout@CHECKOUT_DEVISE_SUCCEDED"
export const CHECKOUT_DEVISE_FAILED = "checkout@CHECKOUT_DEVISE_FAILED"

export const CONFIRM_ORDER_INIT = "checkout@CONFIRM_ORDER_INIT"
export const CONFIRM_ORDER_SUCCEDED = "checkout@CONFIRM_ORDER_SUCCEDED"
export const CONFIRM_ORDER_FAILED = "checkout@CONFIRM_ORDER_FAILED"


export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const DELETE_FROM_COMPARE = "DELETE_FROM_COMPARE";

export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";

export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

export const SET_QUANTITY = "SET_QUANTITY";
