import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {visits} from "../../store/actions"
import uuid from "uuid"
import { useHistory } from "react-router-dom";
const Visits = () => {
const dispatch = useDispatch()
const history = useHistory() 
    const {lastVisited,sendVisit, sessionId} = useSelector((state) =>( {
        lastVisited : state.user.lastVisited,
        sendVisit : state.user.sendVisit,
        sessionId : state.user.sessionId
    }))
useEffect(() => {
    if (moment(new Date()).diff(moment(lastVisited),'hours') >= 1 && !sendVisit) {
        dispatch(visits({sessionId}))
    }
  console.log(moment(new Date()).diff(moment(lastVisited),'hours') )
},[lastVisited , history.location.pathname])

    return null
}

export default Visits