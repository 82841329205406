// auth
export const LOGIN = "/api/auth/login"
// export const SIGNUP_THIRD_PARTY = "/buyer/third-party"
// export const SIGNUPFACEBOOK = "/buyer/third-party"
// export const LOGINGOOGLE = "/buyer/login-third-party"
// export const LOGINFACEBOOK = "/buyer/login-third-party"

export const LOGOUT = "/api/logout"
export const FORGOT_PASSWORD = "/api/auth/reset-password"
export const FORGOT_PASSWORD_APPMOBIL = "/mapp/auth/reset-password"
export const RESET_PASSWORD = "/api/auth/reset-password"
export const RESET_PASSWORD_MOBILE = "/mapp/auth/reset-password"
export const FETCH_TOKEN = "/api/auth/reset-token/:param"
export const REGISTER = "/api/customers"

export const RATE_ORDERS = "/api/orders/:param/rate"

// contact
export const CONTACT_US = "/api/emails/send"
export const FETCH_FEEDBACKS = "/api/feedbacks"
export const FETCH_FEEDBACK = "/api/feedbacks/:id"

export const FETCH_ORDERRETURNS = "/api/order-returns"
export const FETCH_ORDERRETURN = "/api/order-returns/:id"

export const RETURNPRODUCT = "/api/order-returns"

//Analytics
export const VISITS = "/api/analytics/visits"
export const CHECKOUTS = "/api/analytics/checkouts"



 

// user
export const USER = "/api/customers/:param"
export const UPDATE_USER = "/api/buyer/me/:param"

// product
export const FETCH_PRODUCTS = "/api/products"
export const FILTER_PRODUCTS = "/api/products"
export const FETCH_PRODUCT = "/api/products/:param"
export const FETCH_RELATEDPRODUCT = "/api/products/:id"

// Families
export const FETCH_FAMILIES = "/api/families"
export const FETCH_PRODUCTPACKTYPES = "/api/product-packtypes"

export const FETCH_PRODUCTSYSTEMS = "/api/categories/systems"
export const  CHECKOUT_ORDER_QUOTES  = "/api/order-quotes"

export const  CONFIRM_ORDER  = "/api/orders/:param/update-status"

export const FETCH_TAXATION = "/api/taxation"


// categories
export const FETCH_CATEGORIES = "/api/categories"
export const FETCH_COLORS = "/api/colors"
export const FETCH_SIZES = "/api/families"
export const FETCH_PRODQUALITYS = "/api/product-qualities"

export const FETCH_FAQ = "/api/faq"
export const FETCH_NOTIFICATION = "/api/notifications"

export const READ_NOTIFICATION = "/api/notifications/:param"
export const FCM_TOKEN = "/api/auth/me/fcm-token"

export const FETCH_ORDERQUOTES = "/api/order-quotes"

export const DOWNLOAD_DEVIS = "/api/order-quotes/:param/download"
export const DOWNLOAD_ORDER = "/api/orders/:param/download"

// orders
export const CHECKOUT_ORDER = "/api/orders"
export const MY_ORDERS = "/api/orders"

// event
export const FETCH_EVENTS = "/api/events";
export const FETCH_EVENT = "/api/events/:param";

// booking
export const MY_BOOKING = "/api/reservations/user/:param";
export const UPDATE_BOOKING = "/api/reservations/:param";
export const BOOK_TABLE = "/api/reservations";


// discount
export const DISCOUNT = "/api/discounts"


