import moment from "moment";
import { PURGE } from "redux-persist";
import { ACTIONS } from "./../constants"
import uuid from "uuid"



const initialState = {
  user: null,
  orderQuotes: [],
  lastVisited : moment(new Date()).subtract(2,"hours"),
  sessionId : uuid() ,
  sendVisit : false,
  feedbacks: [],
  orderReturns :[],
  notifications : [],
  totalNotifications : [],
  orderReturn :[],
  orderReturnPage :1 ,
  totalOrderReturn :0,
  orderQuotePage: 1,
  totalfeedbacks: 0,
  orders: [],
  downloadLink : "" ,
  orderPage: 1,
  feedbackPage: 1,
  totalOrders: 0,
  
  isFetching: false,
  isLoading: false,
  success: false,
  error: null,
};


export default (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {
    case ACTIONS.CLEAR_ERROR: {
      return { ...state, success: false, error: null };
    }

    case ACTIONS.FETCH_USER_INFORMATION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_USER_INFORMATION_SUCCEDED: {
      return { ...state, isFetching: false, user: payload };
    }
    case ACTIONS.FETCH_USER_INFORMATION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    //analytics

    //VISISTS


    case ACTIONS.VISITS_INIT : {
      return { ...state,sendVisit : true, error: null }
    }
    case ACTIONS.VISITS_SUCCEDED : {
      return { ...state, lastVisited: moment( new Date()), sendVisit: false, error: null }
    }
    case ACTIONS.VISITS_FAILED : {
      return { ...state, sendVisit: false, error: payload }
    }
//CHECKOUTS

    case ACTIONS.CHECKOUTS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.CHECKOUTS_SUCCEDED : {
      return { ...state, chouckout: payload, isFetching: false, error: null }
    }
    case ACTIONS.CHECKOUTS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }
   
    // 

    case ACTIONS.FETCH_USER_ORDERS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_USER_ORDERS_SUCCEDED: {
      const { results, page, count } = payload
      return { ...state, isFetching: false, orders: results, orderPage: page , totalOrders : count};
    }
    case ACTIONS.FETCH_USER_ORDERS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_ORDERQUOTES_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_ORDERQUOTES_SUCCEDED: {
      const { results, page, count } = payload
      return { ...state, isFetching: false, orderQuotes: results,orderQuotePage: page , totalorderQuotes : count};
    }
    case ACTIONS.FETCH_ORDERQUOTES_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.DOWNLOAD_DEVIS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.DOWNLOAD_DEVIS_SUCCEDED: {
      const { results } = payload
      return { ...state, isFetching: false, downloadLink: results };
      
    }
    case ACTIONS.DOWNLOAD_DEVIS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }


    case ACTIONS.FETCH_ORDERRETURNS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_ORDERRETURNS_SUCCEDED: {
      const { results, page, count } = payload
      return { ...state, isFetching: false, orderReturns: results ,orderReturnPage: page , totalOrderReturn : count };
    }
    case ACTIONS.FETCH_ORDERRETURNS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_ORDERRETURN_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_ORDERRETURN_SUCCEDED: {
      const { results, page, count } = payload
      return { ...state, isFetching: false, orderReturn: results ,orderReturnPage: page , totalOrderReturn : count };
    }
    case ACTIONS.FETCH_ORDERRETURN_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    //notifications 

     
    case ACTIONS.FETCH_NOTIFICATION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_NOTIFICATION_SUCCEDED: {
      const { results, page, count } = payload
      return { ...state, isFetching: false, notifications: results,  totalNotifications: count};
    }
    case ACTIONS.FETCH_NOTIFICATION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.READ_NOTIFICATION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.READ_NOTIFICATION_SUCCEDED: {
      return { ...state, isFetching: false, notifications: state.notifications.map((notification) => notification.id !== payload.id ? notification : ({ ...notification, ...payload}))};
    }
    case ACTIONS.READ_NOTIFICATION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }


    case ACTIONS.FCM_TOKEN_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.FCM_TOKEN_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.FCM_TOKEN_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.LOGOUT_SUCCEDED: {
      return { ...state, notifications: [] };
    }
    case PURGE: {
      return { ...state, notifications: [] };
    }


    case ACTIONS.FETCH_FEEDBACKS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_FEEDBACKS_SUCCEDED: {
      const { results, page, count } = payload
      return { ...state, isFetching: false, feedbacks: results,feedbackPage: page , totalfeedbacks: count};
    }
    case ACTIONS.FETCH_FEEDBACKS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }


    case ACTIONS.UPDATE_USER_INFORMATION_INIT: {
      return { ...state, isFetching: true, success: false, error: null };
    }
    case ACTIONS.UPDATE_USER_INFORMATION_SUCCEDED: {
      return { ...state, isFetching: false, success: true, user: payload };
    }
    case ACTIONS.UPDATE_USER_INFORMATION_FAILED: {
      return { ...state, isFetching: false, success: false, error: payload };
    }

   


    //booking
    case ACTIONS.FETCH_USER_BOOKINGS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_USER_BOOKINGS_SUCCEDED: {
      return { ...state, isFetching: false, bookings: payload };
    }
    case ACTIONS.FETCH_USER_BOOKINGS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.UPDATE_USER_BOOKING_INIT: {
      return { ...state, isFetching: true, success: false, error: null };
    }
    case ACTIONS.UPDATE_USER_BOOKING_SUCCEDED: {
      return { ...state, isFetching: false, success: true, user: payload };
    }
    case ACTIONS.UPDATE_USER_BOOKING_FAILED: {
      return { ...state, isFetching: false, success: false, error: payload };
    }

    default: {
      return state;
    }
  }
}
