import { ACTIONS } from "./../constants"
import { isEmpty } from "lodash"

const initialState = { 
  products: [],
  success: false,
  failed : false,
  total: 0,
  totalAmount: 0
}


export default (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_THE_CART : {
      return { ...state, ...initialState }
    }

    case ACTIONS.CHECKOUT_ORDER_SUCCEDED : {
      return { ...state, ...initialState }
    }
    case ACTIONS.FETCH_PRODUCT_INIT: {
      return { ...state,success : false , failed : false };
    }
    
    case ACTIONS.ADD_TO_CART : {

      const { quantity, product, ...props } = payload
      // console.log(quantity)
      const selectedProductColor = props.selectedProductColor
     
      const uuid = product.id 
      const oldProduct = state.products.find((item) => item.uuid === uuid)
      
      let newProduct = {}
      let success = true
      let failed = false
    if (!isEmpty(oldProduct)){
      // console.log(payload)
      const newQuantity = (quantity + oldProduct.quantity) // < product.stock ? (quantity +oldProduct.quantity) : product.stock
     // if((quantity + oldProduct.quantity ) > product.stock){
     //   success = false
     //   failed = true
     // }
     
      newProduct = ({ ...oldProduct, quantity: newQuantity })
    }else{
        newProduct = ({ product: { ...product }, uuid,selectedProductColor, quantity, ...props,  })
    }
      
      const products = state.products.filter((item) => item.uuid !== uuid )
      const allProducts = [ ...products, newProduct]
      const totalAmount = allProducts.reduce((accumulator, data) => accumulator += (data.product.discount ? data.product.discount.salePrice : data.product.price) * data.quantity , 0)
      return { ...state, success, failed, products: allProducts, total: state.total + quantity, totalAmount }
    }
    case ACTIONS.REMOVE_FROM_CART : {
      const { uuid } = payload
      const products = state.products.filter((item) => item.uuid !== uuid )
      const total = products.reduce((accumulator, item) => accumulator + item.quantity, 0)
      const totalAmount = products.reduce((accumulator, data) => accumulator += (data.product.discount ? data.product.discount.salePrice : data.product.price) * data.quantity , 0)
      return { ...state, products , total, totalAmount }
    }

    case ACTIONS.DELETE_ALL_FROM_CART: {
      return { ...state, ...initialState }
    }

    case ACTIONS.SUBTRACT_QUANTITY : {
      const { uuid } = payload
      let quantity = 1
      const products = state.products.reduce((accumulator, item) => {
        const newItem = { ...item }
        if (item.uuid === uuid && item.quantity > quantity ){
          newItem.quantity = newItem.quantity - quantity
        }
        accumulator.push(newItem)
        return accumulator
      }, [])
      const totalAmount = products.reduce((accumulator, data) => accumulator += (data.product.discount ? data.product.discount.salePrice : data.product.price) * data.quantity , 0)

      return { ...state, products, total: state.total > quantity ? state.total - quantity : state.total, totalAmount }
    }

    case ACTIONS.ADD_QUANTITY : {
      const { uuid } = payload
      let quantity = 1
      const products = state.products.reduce((accumulator, item) => {
        const newItem = { ...item }
        if (item.uuid === uuid){
          newItem.quantity += quantity
        }
        accumulator.push(newItem)
        return accumulator
      }, [])

      const totalAmount = products.reduce((accumulator, data) => accumulator += (data.product.discount ? data.product.discount.salePrice : data.product.price) * data.quantity , 0)
      return { ...state, products, total: state.total + quantity, totalAmount }
    }

    case ACTIONS.SET_QUANTITY : {
      const { uuid , quantity} = payload
      console.log(quantity)
      const products = state.products.reduce((accumulator, item) => {
        const newItem = { ...item }
        console.log(item)
        if (item.uuid === uuid && quantity > 0){
          newItem.quantity =  item.product.stock
        }
        accumulator.push(newItem)
        return accumulator
      }, [])

      const totalAmount = products.reduce((accumulator, data) => accumulator += (data.product.discount ? data.product.discount.salePrice : data.product.price) * data.quantity , 0)
      return { ...state, products, total: products.reduce((accum, item) => accum + item.quantity, 0), totalAmount }
    }
   
    default: {
      return state
    }
  }
}
