import { lazy } from 'react'


const MyAccount = lazy(() =>  import('./../../pages/my-account/MyAccount'))
const MyOrder = lazy(() => import("./../../pages/my-account/MyOrders"));
const Devis = lazy(() => import("./../../pages/my-account/Devis"));
const Reclamation = lazy(() => import("./../../pages/reclamation/Reclamation"))
const Reclamations = lazy(() => import("./../../pages/reclamation/Reclamations"))
const OrderReturn = lazy(() => import("./../../pages/my-account/OrderReturn"))



export const myAccountRoute = {
  path: '/my-account',
  component: MyAccount
}

export const myOrderRoute = {
  path: '/my-order',
  component: MyOrder
}

export const devisRoute = {
  path: '/devis',
  component: Devis
}

export const createReclamationRoute = {
  path: '/reclamation/new',
  component: Reclamation
}

export const reclamationListRoute = {
  path: '/reclamation',
  component: Reclamations
}


export const orderReturnRoute = {
  path: '/order-return',
  component: OrderReturn
}


