import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getProductURL } from "../../../helpers";
import { formatPrice, getDiscountPrice } from "../../../helpers/product";
import { checkoutRoute, myCartRoute, productRoute } from "../../../routes";
import FormattedMessage from "../../common/FormattedMessage";
import RenderSrcImg from "../../common/RenderSrcImg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchNotifications , readNotification} from "../../../store/actions";
import moment from "moment";
import 'moment/locale/fr'
moment.locale('fr')

const Notification = () => {
  const [state, setState] = React.useState({
    notification: null,
  });

  const { totalNotifications, notifications, isAuthenticated } = useSelector(
    (state) => ({
      totalNotifications: state.user.totalNotifications,
      notifications: state.user.notifications,
      isAuthenticated: state.authentication.isAuthenticated,
    }),
    shallowEqual
  );


  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated){
      dispatch(fetchNotifications());
    }
  }, [isAuthenticated]);

 const onClickNotification = (modification) => {
   console.log("ok")
   dispatch(readNotification(modification.id ));
 }
  const { addToast } = useToasts();
  return (
    <div className="shopping-cart-content">
      <div class="section-title text-center ">
        <h6>Notifications</h6>
      </div>
      {notifications ? (
        <Fragment>
          <ul>
            {notifications.map((notification, key) => {
              return (
                <li style={{cursor : 'pointer', }} onClick = {() => onClickNotification(notification)} key={key}>
                 
                  
                    <div className="blockquote-footer" style = {{fontWeight : !notification.seen ? 'bold' : 'normal' , fontSize : "small"}}>
                      
                    <i className="pe-7s-check" style = {{color:  notification.seen ? 'green' : 'red'}} /> {"    "}

                     {notification.content}
                     <div className="text-muted">
                       { moment(notification.createdAt).fromNow() }</div>
                    </div>
                    
                 
                </li>
              );
            })}
          </ul>
        </Fragment>
      ) : (
        <p className="text-center">
          <FormattedMessage id="Aucune notification trouver" />
        </p>
      )}
    </div>
  );
};

Notification.propTypes = {
  notifications: PropTypes.array,
};

export default Notification;
