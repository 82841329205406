import PropTypes from 'prop-types'
import React , {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { multilanguage } from 'redux-multilanguage'
import {useDispatch, useSelector} from "react-redux";
import { aboutUsRoute, contactUsRoute, homeRoute, familyProductsRoute,productsRoute ,categoryProductsRoute,deleveryRoute} from '../../routes'
import FormattedMessage from '../common/FormattedMessage'
import {fetchCategories, fetchFamilies } from "../../store/actions";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCategories())
  }, [])
  const {categories = []}  = useSelector((state) => ({
    categories: state.product.categories
  }))
  
  return (
    <div
      className={` ${
        sidebarMenu
          ? 'sidebar-menu'
          : `main-menu ${menuWhiteClass || ''}`
      } `}
    >
      <nav>
        <ul>
          <li className='nav-item'>
            <Link to={homeRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.HOME' />
            </Link>
          </li>
          <li className='nav-item'>
            <Link to={productsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.SHOP' />
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
           
               <ul className="submenu">
                 {categories?.map((categorie,key) => {
                   return (
                    <li key = {key}>
                    <Link to={categoryProductsRoute.path.replace(":category", categorie.id )}>
                      {categorie.title}
                    </Link>
                  </li>
                   )}
                   )  }
                </ul>
              </li>
          {/* <li className="nav-item">
                      <Link to={eventsRoute.path} className="nav-link">
                        <FormattedMessage id="MENU.EVENT" />
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={bookingRoute.path} className="nav-link">
                        <FormattedMessage id="MENU.BOOKING" />
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link to={deleveryRoute.path} className="nav-link">
                        <FormattedMessage id="MENU.DELEVERY" />
                      </Link>
                    </li>

          <li className='nav-item' >
            <Link to={aboutUsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.ABOUT_US' />
            </Link>
          </li>
          

          <li className='nav-item'>
            <Link to={contactUsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.CONTACT_US' />
            </Link>
          </li>

          <li className='nav-item'>
            <Link to={productsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.MAKE_ORDER' />
            </Link>
          </li>

          

          {/* <li className='nav-item'>
            <Link to={faqRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.DELEVERY' />
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  )
}

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object
}

export default multilanguage(NavMenu)
