import { ACTIONS } from "./../constants"

//add to cart
export const addToCart = (
  item,
  quantityCount,
  selectedProductColor,
  selectedProductSize
) => ({
      type: ACTIONS.ADD_TO_CART,
      payload: {
        product: item,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
          ? item.selectedProductColor
          : null,
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
          ? item.selectedProductSize
          : null
      }
    });
//decrease from cart
export const decreaseQuantity = (payload) => ({ type: ACTIONS.SUBTRACT_QUANTITY, payload });


export const increaseQuantity = (payload) => ({ type: ACTIONS.ADD_QUANTITY, payload });

export const setQuantity = (payload) => ({ type: ACTIONS.SET_QUANTITY, payload });


//delete from cart
export const deleteFromCart = (payload) => ({ type: ACTIONS.REMOVE_FROM_CART, payload })

//delete all from cart
export const deleteAllFromCart = () => ({ type: ACTIONS.DELETE_ALL_FROM_CART });

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    const variation = (item.variation || [])
      .filter(single => single.color === color).map((single) => single.size).filter(single => single.name === size);
    return (variation && variation.stock) || 10
  }
};
export const addQuantity = (payload) => 
  ({
    type: ACTIONS.ADD_QUANTITY,
    payload,
  })
  //add qt action with quantity number
  
  
  /*
  export const addQuantityWithNumber = (id, qty) => {
    return {
        type: ADD_QUANTITY_WITH_NUMBER,
        id,
        qty
    }
  }
  
  export const addToCart = (payload) =>
  ({
    type: ACTIONS.ADD_TO_CART,
    payload,
  })


export const removeFromCart = (payload) =>
  ({
    type: ACTIONS.REMOVE_FROM_CART,
    payload,
  })




export const subtractQuantity = (payload) =>
  ({
    type: ACTIONS.SUBTRACT_QUANTITY,export const addQuantity = (payload) => 
  ({
    type: ACTIONS.ADD_QUANTITY,
    payload,
  })

    payload,
  })


export const clearCart = () =>
  ({
    type: ACTIONS.CLEAR_THE_CART
  })
*/
