import React, {useLayoutEffect, useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { useToasts } from "react-toast-notifications";
import { fetchNotifications } from '../store/actions';
import { requestForToken, onMessageListener } from './firebase';



const Notification = () => {
 
  const { addToast } = useToasts();

  //const notify = () =>  useToasts(<ToastDisplay/>); 

  const dispatch = useDispatch();

  useEffect(() => {
    requestForToken()
  }, [])

  useLayoutEffect(() => {

    onMessageListener()
    .then((payload) => {
      addToast(payload?.notification?.body, {
        appearance: 'success',
       
      })

      dispatch(fetchNotifications())

        
    })
    .catch((err) => console.log('failed: ', err));
  })

  return (
     null
  )
}

export default Notification