import { ACTIONS, ENDPOINTS, CALL_API, HTTP_METHODS } from "./../constants"


export const fetchTaxations = () =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_TAXATION_INIT,
      success: ACTIONS.FETCH_TAXATION_SUCCEDED,
      fail: ACTIONS.FETCH_TAXATION_FAILED
    },
    endpoint: ENDPOINTS.FETCH_TAXATION,
    method: HTTP_METHODS.GET
  }
})