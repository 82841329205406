import { ACTIONS, ENDPOINTS, CALL_API, HTTP_METHODS } from "./../constants"


export const fetchProducts = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_PRODUCTS_INIT,
        success: ACTIONS.FETCH_PRODUCTS_SUCCEDED,
        fail: ACTIONS.FETCH_PRODUCTS_FAILED
      }, 
      endpoint: ENDPOINTS.FETCH_PRODUCTS,
      params,
      method: HTTP_METHODS.GET
    }
  })


  export const filterAllProducts = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_PRODUCTS_INIT,
        success: ACTIONS.FETCH_PRODUCTS_SUCCEDED,
        fail: ACTIONS.FETCH_PRODUCTS_FAILED
      }, 
      endpoint: ENDPOINTS.FILTER_PRODUCTS,
      params,
      method: HTTP_METHODS.GET
    }
  })

  export const fetchFamilies = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_FAMILIES_INIT,
        success: ACTIONS.FETCH_FAMILIES_SUCCEDED,
        fail: ACTIONS.FETCH_FAMILIES_FAILED
      },
      endpoint: ENDPOINTS.FETCH_FAMILIES,
      method: HTTP_METHODS.GET
    }
  })

  export const fetchDiscount = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_DISCOUNT_INIT,
        success: ACTIONS.FETCH_DISCOUNT_SUCCEDED,
        fail: ACTIONS.FETCH_DISCOUNT_FAILED
      },
      endpoint: ENDPOINTS.FETCH_DISCOUNT,
      method: HTTP_METHODS.GET
    }
  })

  export const fetchProdPackType = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_PRODUCTPACKTYPES_INIT,
        success: ACTIONS.FETCH_PRODUCTPACKTYPES_SUCCEDED,
        fail: ACTIONS.FETCH_PRODUCTPACKTYPES_FAILED
      },
      endpoint: ENDPOINTS.FETCH_PRODUCTPACKTYPES,
      method: HTTP_METHODS.GET
    }
  })

  export const fetchProdSystems = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_PRODUCTSYSTEMS_INIT,
        success: ACTIONS.FETCH_PRODUCTSYSTEMS_SUCCEDED,
        fail: ACTIONS.FETCH_PRODUCTSYSTEMS_FAILED
      },
      endpoint: ENDPOINTS.FETCH_PRODUCTSYSTEMS,
      method: HTTP_METHODS.GET
    }
  })


  


export const fetchCategories = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_CATEGORIES_INIT,
        success: ACTIONS.FETCH_CATEGORIES_SUCCEDED,
        fail: ACTIONS.FETCH_CATEGORIES_FAILED
      },
      endpoint: ENDPOINTS.FETCH_CATEGORIES,
      method: HTTP_METHODS.GET
    }
  })


export const fetchColors = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_COLORS_INIT,
        success: ACTIONS.FETCH_COLORS_SUCCEDED,
        fail: ACTIONS.FETCH_COLORS_FAILED
      },
      endpoint: ENDPOINTS.FETCH_COLORS,
      method: HTTP_METHODS.GET
    }
  })


export const fetchSizes = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_SIZES_INIT,
        success: ACTIONS.FETCH_SIZES_SUCCEDED,
        fail: ACTIONS.FETCH_SIZES_FAILED
      },
      endpoint: ENDPOINTS.FETCH_SIZES,
      method: HTTP_METHODS.GET
    }
  })


export const fetchPodQuality = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_PRODQUALITYS_INIT,
        success: ACTIONS.FETCH_PRODQUALITYS_SUCCEDED,
        fail: ACTIONS.FETCH_PRODQUALITYS_FAILED
      },
      endpoint: ENDPOINTS.FETCH_PRODQUALITYS,
      method: HTTP_METHODS.GET
    }
  })


export const fetchInDiscountProducts = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_IN_DISCOUNT_PRODUCTS_INIT,
        success: ACTIONS.FETCH_IN_DISCOUNT_PRODUCTS_SUCCEDED,
        fail: ACTIONS.FETCH_IN_DISCOUNT_PRODUCTS_FAILED
      },
      endpoint: ENDPOINTS.FETCH_PRODUCTS,
      params: { filter: { search: "", searchFields: [], discount: true, availability: true } },
      method: HTTP_METHODS.GET
    }
  })


export const fetchNewProducts = () =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_NEW_PRODUCTS_INIT,
        success: ACTIONS.FETCH_NEW_PRODUCTS_SUCCEDED,
        fail: ACTIONS.FETCH_NEW_PRODUCTS_FAILED
      },
      endpoint: ENDPOINTS.FILTER_PRODUCTS,
      params: { novelty : true},
      method: HTTP_METHODS.GET
    }
  })


export const filterProducts = (keyword) => 
  ({
    type: ACTIONS.FILTER_PRODUCTS,
    payload: keyword
  })



export const fetchProduct = ({ param }) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_PRODUCT_INIT,
        success: ACTIONS.FETCH_PRODUCT_SUCCEDED,
        fail: ACTIONS.FETCH_PRODUCT_FAILED
      },
      endpoint: ENDPOINTS.FETCH_PRODUCT.replace(":param", param),
      method: HTTP_METHODS.GET
    }
  })


  export const fetchRelatedProducts = ({id}) =>({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_RELATEDPRODUCT_INIT,
        success: ACTIONS.FETCH_RELATEDPRODUCT_SUCCEDED,
        fail: ACTIONS.FETCH_RELATEDPRODUCT_FAILED
      },
      endpoint: ENDPOINTS.FETCH_RELATEDPRODUCT.replace(":id", id),
      method: HTTP_METHODS.GET
    }
  })

  
export const checkout = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.CHECKOUT_ORDER_INIT,
        success: ACTIONS.CHECKOUT_ORDER_SUCCEDED,
        fail: ACTIONS.CHECKOUT_ORDER_FAILED
      },
      auth: true,
      endpoint: ENDPOINTS.CHECKOUT_ORDER,
      method: HTTP_METHODS.POST
    }
  })

  export const createOrderQuotes = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.CHECKOUT_QUOTES_ORDER_INIT,
        success: ACTIONS.CHECKOUT_QUOTES_ORDER_SUCCEDED,
        fail: ACTIONS.CHECKOUT_QUOTES_ORDER_FAILED
      },
      auth: true,
      endpoint: ENDPOINTS.CHECKOUT_ORDER_QUOTES,
      method: HTTP_METHODS.POST
    }
  })


  export const rateOrder = (payload , param) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.RATE_ORDER_INIT,
        success: ACTIONS.RATE_ORDER_SUCCEDED,
        fail: ACTIONS.RATE_ORDER_FAILED
      },
      auth: true,
      endpoint: ENDPOINTS.RATE_ORDERS.replace(":param", param),
      method: HTTP_METHODS.PATCH
    }
  })

  export const confirmOrder = (params,payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.CONFIRM_ORDER_INIT,
        success: ACTIONS.CONFIRM_ORDER_SUCCEDED,
        fail: ACTIONS.CONFIRM_ORDER_FAILED
      },
      auth: true,
      endpoint: ENDPOINTS.CONFIRM_ORDER.replace(":param",params.param),
      method: HTTP_METHODS.PATCH
    }
  })
  
