import { lazy } from 'react'

const Product = lazy(() =>  import('./../../pages/shop/Product'))
const Products = lazy(() =>  import('./../../pages/shop/Shop'))


export const productRoute = {
  path: '/our-product/:param',
  component: Product
}
export const familyProductsRoute = {
  path: '/our-products/family/:family',
  component: Products
}
export const categoryProductsRoute = {
  path: '/our-products/category/:category',
  component: Products
}

export const productsRoute = {
  path: '/our-products',
  component: Products
}


