import { multilanguage } from 'redux-multilanguage'
 
const FormattedMessage = ({ id, strings, ...props }) => {
  let text = (strings[id] || (id || null))
  
  if (props && text){
    Object.keys(props).forEach((key) => {
      text = text.replace(`{${key}}`, props[key])
    })
  }

  return text
} 


export default multilanguage(FormattedMessage)
