export const PHONE = "+216 71 11 60 00 ";
export const ADDRESS = "52 rue 8601 Z1 charguia 1 BP194"
export const EMAIL = "commercial.tork@florus.com.tn";

export const FB_LINK = "http://www.sancella.com.tn/";
export const INSTAGRAM_LINK = "https://www.linkedin.com/company/sancella-tunisie/";
export const TWITTER_LINK = "https://twitter.com/hashtag/sancella?src=hashtag_click"

export const RECIVER_MAIL = "contact@infinitymgt.fr"


export const BANNER_DATA = [
  {
    "id": 1,
    "image": "/assets/img/banner/banner-5.png",
    "title": "Distributeur",
    "subtitle": "Que vous vouliez parfaire votre image auprès de vos clients, maintenir des normes d'hygiène élevées ou contrôler les coûts : quel que soit votre objectif, nous avons ce qu'il vous faut.  Tork met à votre disposition une large gamme de distributeurs complète et fonctionnelle au design moderne : des distributeurs essuie main, de papier toilettes, de savons et des accessoires.",
    "link": "/our-products",
    "textAlign": "right"
  },
  {
    "id": 2,
    "image": "/assets/img/banner/banner-4.png",
    "title": "Recharge",
    "subtitle": "Que votre priorité soit le luxe, un entretien aisé ou la rentabilité, vous pouvez choisir parmi de nombreuses qualités de papier et vous trouverez à coup sûr celle qui répondra à vos besoins. Nos recharges Tork papier, sont disponibles en trois niveaux de qualité principaux : premium, Advanced et Universal. Tork vous offre aussi une large gamme de formules pour un lavage des mains classique ou professionnel,pour la douche ou pour hydrater les mains. Nos solutions de soins pour la peau Tork sont spécialement étudiées pour répondre aux attentes élevées en matière d’hygiène. Nos consommables de qualité supérieure sont proposés en différents formats : mousse ou liquide. Ils sont testés et approuvés sous contrôle dermatologique.",
    "link": "/our-products",
    "textAlign": "left"
  }
 
]


export const BRAND_LOGO_DATA = [
  {
    "id": "1",
    "image": "/assets/img/brand-logo/brand-logo-44.png",
    "text" : "Tork"
  },
  // {
  //   "id": "2",
  //   "image": "/assets/img/brand-logo/sanceya6.png",
  //   "text" : "Sensea"
  // },
  // {
  //   "id": "3",
  //   "image": "/assets/img/brand-logo/brand-logo-2.png",
  //   "text" : "Hygiene Feminine"
  // },
  // {
  //   "id": "4",
  //   "image": "/assets/img/brand-logo/brand-logo-3.png",
  //   "text" : "Hygiene Bebe"
  // },
  // {
  //   "id": "5",
  //   "image": "/assets/img/brand-logo/brand-logo-4.png",
  //   "text" : "Incontinence"
  // },
  // {
  //   "id": "6",
  //   "image": "/assets/img/brand-logo/brand-logo-5.png",
  //   "text" : "Collectivite"
  // }
  
]


export const PARTNER_LOGO_DATA = [
  {
    "id": "1",
    "image": "/assets/img/brand-logo/GAT Assurance.png",
    "text" : "GAT"
  },
  {
    "id": "2",
    "image": "/assets/img/brand-logo/UBCI.jpg",
    "text" : "UBCI"
  },
  {
    "id": "3",
    "image": "/assets/img/brand-logo/Carthagene Centre hospitalier International.png",
    "text" : "Carthagene"
  },
  {
    "id": "4",
    "image": "/assets/img/brand-logo/btl.png",
    "text" : "BTL"
  },
  {
    "id": "5",
    "image": "/assets/img/brand-logo/Delice Danone.jpg",
    "text" : "Delice Danone"
  },
  {
    "id": "6",
    "image": "/assets/img/brand-logo/IBEROSTAR Hotels & Resorts.jpg",
    "text" : "IBEROSTAR Hotels & Resorts"
  },
  {
    "id": "7",
    "image": "/assets/img/brand-logo/La Rose Clinique.jpg",
    "text" : "La Rose Clinique"
  },
  {
    "id": "8",
    "image": "/assets/img/brand-logo/Magasin Général.png",
    "text" : "Magasin Général"
  },
  {
    "id": "9",
    "image": "/assets/img/brand-logo/MAGIC HOTELS.png",
    "text" : "MAGIC HOTELS"
  },
  {
    "id": "10",
    "image": "/assets/img/brand-logo/Mövenpick.jpg",
    "text" : "Mövenpick Hotels & Resorts"
  },
  
  {
    "id": "12",
    "image": "/assets/img/brand-logo/Polyclinique l'Excellence.png",
    "text" : "Polyclinique l'Excellence"
  },
  {
    "id": "13",
    "image": "/assets/img/brand-logo/Poulina group holding.png",
    "text" : "Poulina group holding"
  },
  {
    "id": "14",
    "image": "/assets/img/brand-logo/Unimed laboratoire.png",
    "text" : "Unimed"
  },
  {
    "id": "11",
    "image": "/assets/img/brand-logo/OLA Energy.png",
    "text" : "OLA Energy"
  },
  
]

export const FUN_FACT_DATA = [
  {
    id: 1,
    iconClass: "pe-7s-portfolio",
    countNum: 110,
    title: "Nombre de marchés ou Tork est disponible"
  },
  {
    id: 2,
    iconClass: "pe-7s-cup",
    countNum: "6.5",
    title: "Millions de distributeurs placés chaque année"
  },
  {
    id: "3",
    iconClass: "pe-7s-light",
    countNum: 27000,
    title: "Clients interrogés"
  },
  {
    id: "4",
    iconClass: "pe-7s-smile",
    countNum: +200 ,
    title: "Nombre de brevets hygiène professionnelle accordés ou en attente"
  }
]


export const TEXT_GRID_DATA = [
  {
    id: 1,
    title: "ABOUT_US.OUR_STORY.TITLE",
    text: ["ABOUT_US.OUR_STORY.FIRST"]
  },
  {
    id: 2,
    title: "ABOUT_US.OUR_VALUES.TITLE",
    text: ["ABOUT_US.OUR_VALUES.FIRST"]
  },
  {
    id: 3,
    title: "ABOUT_US.OUR_PROMISE.TITLE",
    text: ["ABOUT_US.OUR_PROMISE.FIRST"]
  }
]
