import { ACTIONS, ENDPOINTS, CALL_API, HTTP_METHODS } from "./../constants"


export const fetchMyorderQuotes = (params) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_ORDERQUOTES_INIT,
      success: ACTIONS.FETCH_ORDERQUOTES_SUCCEDED,
      fail: ACTIONS.FETCH_ORDERQUOTES_FAILED
    }, 
    params,
    auth: true,
    endpoint: ENDPOINTS.FETCH_ORDERQUOTES,
    method: HTTP_METHODS.GET
  }
}) 


export const fetchNotifications = (params) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_NOTIFICATION_INIT,
      success: ACTIONS.FETCH_NOTIFICATION_SUCCEDED,
      fail: ACTIONS.FETCH_NOTIFICATION_FAILED
    }, 
    params,
    auth: true,
    endpoint: ENDPOINTS.FETCH_NOTIFICATION,
    method: HTTP_METHODS.GET
  }
})



export const readNotification = (param) => ({
  type: CALL_API,
  
  meta: {
    actions: {
      init: ACTIONS.READ_NOTIFICATION_INIT,
      success: ACTIONS.READ_NOTIFICATION_SUCCEDED,
      fail: ACTIONS.READ_NOTIFICATION_FAILED,
    },
    auth: true,
    endpoint: ENDPOINTS.READ_NOTIFICATION.replace(":param", param),
    method: HTTP_METHODS.PATCH,
  },
});




  export const fetchMyOrders = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_USER_ORDERS_INIT,
        success: ACTIONS.FETCH_USER_ORDERS_SUCCEDED,
        fail: ACTIONS.FETCH_USER_ORDERS_FAILED
      }, 
      params,
      auth: true,
      endpoint: ENDPOINTS.MY_ORDERS,
      method: HTTP_METHODS.GET
    }
  })

  export const fetchFeedbacks = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_FEEDBACKS_INIT,
        success: ACTIONS.FETCH_FEEDBACKS_SUCCEDED,
        fail: ACTIONS.FETCH_FEEDBACKS_FAILED
      }, 
      params,
      auth: true,
      endpoint: ENDPOINTS.FETCH_FEEDBACKS,
      method: HTTP_METHODS.GET
    }
  })

  export const fetchFeedback = ({id}) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_FEEDBACK_INIT,
        success: ACTIONS.FETCH_FEEDBACK_SUCCEDED,
        fail: ACTIONS.FETCH_FEEDBACK_FAILED
      }, 
      
      auth: true,
      endpoint: ENDPOINTS.FETCH_FEEDBACK.replace(":id", id),
      method: HTTP_METHODS.GET
    }
  })
  export const fcmToken = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.FCM_TOKEN_INIT,
        success: ACTIONS.FCM_TOKEN_SUCCEDED,
        fail: ACTIONS.FCM_TOKEN_FAILED
      },
      endpoint: ENDPOINTS.FCM_TOKEN,
      method: HTTP_METHODS.POST
    }
  })

  //analytics
  export const visits = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.VISITS_INIT,
        success: ACTIONS.VISITS_SUCCEDED,
        fail: ACTIONS.VISITS_FAILED
      },
      endpoint: ENDPOINTS.VISITS,
      method: HTTP_METHODS.POST,
     
    }
  })

  export const checkouts = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.CHECKOUTS_INIT,
        success: ACTIONS.CHECKOUTS_SUCCEDED,
        fail: ACTIONS.CHECKOUTS_FAILED
      },
      endpoint: ENDPOINTS.CHECKOUTS,
      method: HTTP_METHODS.POST,
      auth: true
    }
  })
//
  export const fetchOrderReturns = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_ORDERRETURNS_INIT,
        success: ACTIONS.FETCH_ORDERRETURNS_SUCCEDED,
        fail: ACTIONS.FETCH_ORDERRETURNS_FAILED
      }, 
      params,
      auth: true,
      endpoint: ENDPOINTS.FETCH_ORDERRETURNS,
      method: HTTP_METHODS.GET
    }
  })

  export const fetchOrderReturn = ({id}) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_ORDERRETURNS_INIT,
        success: ACTIONS.FETCH_ORDERRETURNS_SUCCEDED,
        fail: ACTIONS.FETCH_ORDERRETURNS_FAILED
      }, 
      
      auth: true,
      endpoint: ENDPOINTS.FETCH_ORDERRETURN.replace(":id", id),
      method: HTTP_METHODS.GET
    }
  })

export const fetchMyBookings = (param) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_USER_BOOKINGS_INIT,
      success: ACTIONS.FETCH_USER_BOOKINGS_SUCCEDED,
      fail: ACTIONS.FETCH_USER_BOOKINGS_FAILED,
    },
    endpoint: ENDPOINTS.MY_BOOKING.replace(":param", param),
    method: HTTP_METHODS.GET,
  },
});

export const updateBooking = (param, payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.UPDATE_USER_INFORMATION_INIT,
      success: ACTIONS.UPDATE_USER_INFORMATION_SUCCEDED,
      fail: ACTIONS.UPDATE_USER_INFORMATION_FAILED,
    },
    endpoint: ENDPOINTS.UPDATE_BOOKING.replace(":param", param),
    method: HTTP_METHODS.PATCH,
  },
});


export const updateUser = (param, payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.UPDATE_USER_INFORMATION_INIT,
        success: ACTIONS.UPDATE_USER_INFORMATION_SUCCEDED,
        fail: ACTIONS.UPDATE_USER_INFORMATION_FAILED
      },
      endpoint: ENDPOINTS.UPDATE_USER.replace(":param", param),
      method: HTTP_METHODS.PUT
    }
  })
