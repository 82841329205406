import React from "react";

const MobileSearch = () => {
  return (
    <div className="offcanvas-mobile-search-area" style={{textAlign: 'center',color: '#007bff'}}>
      <h5  style={{color: '#113960'}} >Menu</h5>
      {/* <form action="#">
        <input type="search" placeholder="Search ..." /> 
        <button type="submit">
          <i className="fa fa-search" />
        </button>
      </form> */}
    </div>
  );
};

export default MobileSearch;
