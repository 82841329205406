import { lazy } from 'react'

const Login = lazy(() =>  import('./../../pages/authentication/Login'))
const SignUp = lazy(() =>  import('./../../pages/authentication/SignUp'))
const ForgetPassword = lazy(() =>  import('./../../pages/authentication/ForgetPassword'))
const ResetPassword = lazy(() =>  import('./../../pages/authentication/ResetPassword'))
const ForgetPasswordMobilApp = lazy(() =>  import('./../../pages/authentication/ForgetPasswordMobilApp'))
const ResetPasswordMobileApp = lazy(() =>  import('./../../pages/authentication/ResetPasswordMobileApp'))

export const loginRoute = {
  path: '/login',
  component: Login
}

export const forgetPasswordRoute = {
  path: '/forget-password',
  component: ForgetPassword
}

export const ResetPasswordRoute = {
  path: '/auth/reset-password/:email/:token',
  component: ResetPassword
}

export const signupRoute = {
  path: '/signup',
  component: SignUp
}

export const forgetPasswordMobilApp ={
  path: '/mapp/auth/reset-password-Mobile/:email/:token',
  component: ForgetPasswordMobilApp
}

export const resetPasswordMobileAppRoute ={
  path: '/mapp/auth/reset-password/:email/:token',
  component: ResetPasswordMobileApp
}
