import PropTypes from 'prop-types'
import React, { useEffect,useState, lazy } from 'react'
import ScrollToTop from './../helpers/Scroll-top'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { multilanguage, changeLanguage, loadLanguages } from 'redux-multilanguage'
import { connect, useDispatch } from 'react-redux'
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic'
import Header from './components/Header'
import Footer from './components/Footer'
import * as allRoutes from './../routes'
import Notification from './../notificationFireBase/Notification'
//import { getToken, onMessageListener } from '../helpers/firebase';


const PageNotFound = lazy(() => import('./../pages/error-pages/PageNotFound'))

const BaseLayout = ({ headerContainerClass, headerTop, headerPaddingClass, headerPositionClass }) => {

  // const [isTokenFound, setTokenFound] = useState(false);
  //   getToken(setTokenFound);
  

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      loadLanguages({
        languages: {
          en: require('./../translations/french.json'),
        }
      })
    )
    dispatch(changeLanguage("en"))
    // eslint-disable-next-line
  }, [])

  return (
    <ToastProvider placement='top-right'>
      <Notification/>
      <BreadcrumbsProvider>
        <ScrollToTop>
          <>
            <Header
              layout={headerContainerClass}
              top="visible"
              headerPaddingClass={headerPaddingClass}
              headerPositionClass={headerPositionClass}
            />
            <Switch>
              <Route
                exact
                path='/'
                render={() => <Redirect from='/' to={allRoutes.homeRoute.path} />}
              />
              {Object.keys(allRoutes).map((key) => <Route key={key} {...allRoutes[key]} />)}
              <Route component={PageNotFound} />
            </Switch>
             
            <Footer
              backgroundColorClass='bg-gray'
              spaceTopClass='pt-100'
              spaceBottomClass='pb-40'
            />
          </>
        </ScrollToTop>
      </BreadcrumbsProvider>
    </ToastProvider>
  )
}

BaseLayout.propTypes = {
  dispatch: PropTypes.func
}

export default connect()(multilanguage(BaseLayout))
