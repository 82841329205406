import { ACTIONS, ENDPOINTS, CALL_API, HTTP_METHODS } from "./../constants"


export const fetchFaq = (params) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_FAQ_INIT,
        success: ACTIONS.FETCH_FAQ_SUCCEDED,
        fail: ACTIONS.FETCH_FAQ_FAILED
      }, 
      endpoint: ENDPOINTS.FETCH_FAQ,
      params,
      method: HTTP_METHODS.GET
    }
  })